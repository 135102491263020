/**
 * BlogAuthorOverview Page
 * @namespace BlogAuthorOverviewPage
 */
import React from "react";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {withTranslation} from "react-i18next";
import {getAllFaq} from "./FaqUtil";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import userStore from "../../components/userManagement/userStore";
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../../helper/util";
import history from "../../helper/browserHistory";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";

@observer
class FaqManagementPage extends React.Component {

    @observable modalIsOpen = false;
    @observable modalText = "";
    @observable loading = false;
    @observable error = false;

    constructor(props) {
        super(props);
        if (!userStore.isAdmin) {
            history.push("/");
        }
        this.state = {
            faq: [],
            faqEdit: false,
            faqNew: false,
            faqText: '',
            faqTitle: '',
            faqPosition: 0,
            faqActive: 0,
            newFaqText: '',
            newFaqTitle: '',
            newFaqPosition: 0,
            newFaqActive: 1,
        }
        this.selectedFaq = {};
    }

    componentDidMount() {
        getAllFaq().then(faqs => {
            this.setState({...this.state, faq: faqs});
        })
    }

    addFaq(newFaq) {
        this.loading = true;
        fetch(config.BASE_URL + "faq/", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({faq: newFaq})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                getAllFaq().then(result => {
                    this.setState({...this.state, faq: result});
                })
            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }


    editFaq(editFaq) {
        fetch(config.BASE_URL + "faq/", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({faq: editFaq, id: editFaq.id})
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                getAllFaq().then(result => {
                    this.setState({...this.state, faq: result});
                })
            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    deleteFaq(faqId) {
        this.loading = true;
        fetch(config.BASE_URL + "faq/" + faqId, {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                getAllFaq().then(result => {
                    this.setState({...this.state, faq: result});
                })
            } else {
                //TODO do something
            }
        }).catch(
            error => {

                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    deleteFaqClicked(faq) {
        this.deleteFaq(faq.id);
    }

    editFaqClicked(faq) {
        this.setState({
            faqEdit: true,
            faqTitle: faq.title,
            faqText: faq.text,
            faqPosition: faq.position,
            faqActive: faq.isActive,
        })
        this.selectedFaq = faq;
    }

    saveNewButtonClicked() {
        let active;
        if (this.state.newFaqActive) {
            active = 1
        } else {
            active = 0
        }
        let newFaq = {
            title: this.state.newFaqTitle,
            text: this.state.newFaqText,
            position: this.state.newFaqPosition,
            isActive: active
        };
        this.addFaq(newFaq);
    }

    activeChanged(faqToEdit, a, e) {
        faqToEdit.isActive = e.checked ? 1 : 0;
        this.editFaq(faqToEdit)
        this.setState({...this.state})
    }

    updateFaq() {
        this.selectedFaq = {
            title: this.state.faqTitle,
            text: this.state.faqText,
            position: this.state.faqPosition,
            isActive: this.state.faqActive,
            id: this.selectedFaq.id,
        }
        this.editFaq(this.selectedFaq)
    }

    renderFaqTable(faq) {
        let faqRows = null;
        const {i18n} = this.props;
        if (typeof faq !== "undefined") {
            faqRows = faq.map(aFaq =>
                <Table.Row key={aFaq.id}>
                    <Table.Cell onClick={this.editFaqClicked.bind(this, aFaq)}>{aFaq.title}</Table.Cell>
                    <Table.Cell
                        onClick={this.editFaqClicked.bind(this, aFaq)}>{aFaq.text} </Table.Cell>
                    <Table.Cell><Checkbox checked={aFaq.isActive}
                                          onChange={this.activeChanged.bind(this, aFaq)}/></Table.Cell>
                    <Table.Cell onClick={this.editFaqClicked.bind(this, aFaq)}>{aFaq.position} </Table.Cell>
                    <Table.Cell>
                        <Button color={'red'} onClick={this.deleteFaqClicked.bind(this, aFaq)}>
                            {i18n.t('common:common.delete')}
                        </Button>
                    </Table.Cell>
                </Table.Row>);
        }
        return faqRows;
    }

    render() {
        const {i18n} = this.props;
        return (
            <div className='table-layout'>
                <Button onClick={() => {
                    this.setState({...this.state, faqNew: true})
                }}>{i18n.t("faq:faq.button_new")}</Button>
                <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{i18n.t("faq:faq.question")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18n.t("faq:faq.answer")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18n.t("faq:faq.active")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18n.t("faq:faq.position")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18n.t("faq:faq.delete")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderFaqTable(this.state.faq)}
                    </Table.Body>
                </Table>
                <Modal open={this.state.faqEdit}>
                    <Modal.Header>{i18n.t("faq:faq.edit")}</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Field>
                                <label>{i18n.t("faq:faq.question")}</label>
                                <input type="text" value={this.state.faqTitle} onChange={e => {
                                    this.setState({...this.state, faqTitle: e.target.value})
                                }}/>
                            </Form.Field>
                            <Form.Field>
                                <label>{i18n.t("faq:faq.answer")}</label>
                                <TextArea value={this.state.faqText} onChange={(e) => {
                                    this.setState({...this.state, faqText: e.target.value})
                                }}/>
                            </Form.Field>
                            <Form.Field>
                                <label>{i18n.t("faq:faq.position")}</label>
                                <input type={"number"} value={this.state.faqPosition}
                                       onChange={e => {
                                           this.setState({...this.state, faqPosition: e.target.value})
                                       }}/>
                            </Form.Field>
                            <Form.Field>
                                <Table.Cell><Checkbox checked={this.state.faqActive}
                                                      onChange={(e, data) => {
                                                          this.setState({...this.state, faqActive: data.checked})
                                                      }}
                                                      label={i18n.t("faq:faq.active")}/>
                                </Table.Cell>
                            </Form.Field>
                            <Button color={'green'}
                                    onClick={() => {
                                        this.updateFaq()
                                        this.setState({...this.state, faqEdit: false})
                                    }}>{i18n.t("common:common.save")}</Button>
                            <Button color={'red'}
                                    onClick={() => {
                                        this.setState({...this.state, faqEdit: false})
                                    }}>{i18n.t("common:common.cancel")}</Button>
                        </Form>
                    </Modal.Content>
                </Modal>
                <Modal open={this.state.faqNew}>
                    <Modal.Header>{i18n.t("faq:faq.new")}
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Field>
                                <label>{i18n.t("faq:faq.question")}</label>
                                <input onChange={e => {
                                    this.setState({...this.state, newFaqTitle: e.target.value})
                                }}/>
                            </Form.Field>
                            <Form.Field>
                                <label>{i18n.t("faq:faq.answer")}</label>
                                <TextArea value={this.state.newFaqText}
                                          onChange={(e) => {
                                              this.setState({...this.state, newFaqText: e.target.value})
                                          }}/>
                            </Form.Field>
                            <Form.Field>
                                <label>{i18n.t("faq:faq.position")}</label>
                                <input type={"number"}
                                       onChange={e => {
                                           this.setState({...this.state, newFaqPosition: e.target.value})
                                       }}/>
                            </Form.Field>
                            <Form.Field>
                                <Table.Cell><Checkbox checked={this.state.newFaqActive}
                                                      onChange={(e, data) => {
                                                          this.setState({...this.state, newFaqActive: data.checked})
                                                      }}
                                                      label={i18n.t("faq:faq.active")}/>
                                </Table.Cell>
                            </Form.Field>
                            <Button color={'green'}
                                    onClick={() => {
                                        this.saveNewButtonClicked()
                                        this.setState({...this.state, faqNew: false})
                                    }}
                            >{i18n.t("common:common.save")}
                            </Button>
                            <Button
                                color={'red'} onClick={() => {
                                this.setState({...this.state, faqNew: false})
                            }}>{i18n.t("common:common.cancel")}</Button>
                        </Form></Modal.Content>
                </Modal>
            </div>
        )
    }
}

export default withTranslation(['faq', 'common'])(FaqManagementPage)
